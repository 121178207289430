import "../src/styles/global.scss";
import React, { useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import Main from "./components/Main/Main";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";


function App() {
  const [activeSection, setActiveSection] = useState("WORK");

  return (
    <div className="App">
      <ToastContainer />
      <Navbar
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />
      <TransitionGroup>
        <CSSTransition
          timeout={300}
          classNames="page-transition"
          key={activeSection}
        >
          <Main
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default App;
