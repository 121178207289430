import React, { useState, useEffect } from "react";
import Confetti from "react-dom-confetti";

function ConfettiComponent() {
  const [confetti, setConfetti] = useState(false);

  useEffect(() => {
    const handleConfetti = () => {
      setConfetti(true);
      setTimeout(() => setConfetti(false), 2000);
    };

    window.addEventListener("confetti", handleConfetti);

    return () => {
      window.removeEventListener("confetti", handleConfetti);
    };
  }, []);

  return (
    <Confetti
      active={confetti}
      config={{
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 70,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: "10px",
        height: "10px",
        perspective: "500px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
      }}
    />
  );
}

export default ConfettiComponent;
