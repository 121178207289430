import React from "react";
import "./SubmitButton.scss";

function SubmitButton({ type, loading, success, onClick }) {
  return (
    <div type={type} className={`btn-submit ${loading ? 'loading' : ''} ${success ? 'checked' : ''}`} onClick={onClick}>
      <span>Envoyer</span>
      <div className="rotate"></div>
    </div>
  );
}


export default SubmitButton;
