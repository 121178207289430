import React, { useState } from "react";
import "./Main.scss";
import Article from "../Article/Article";
import ContactForm from "../ContactForm/ContactForm";
import About from "../About/About";
import Careers from "../Careers/Careers";

const Main = ({ activeSection, setActiveSection }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleLeftClick = () => {
    const nextIndex =
      activeIndex - 1 >= 0 ? activeIndex - 1 : articles.length - 1;
    setActiveIndex(nextIndex);
  };

  const handleRightClick = () => {
    const nextIndex =
      activeIndex + 1 <= articles.length - 1 ? activeIndex + 1 : 0;
    setActiveIndex(nextIndex);
  };

  const articles = [
    {
      title: "Plateforme restaurants et sourcing local",
      description: `Plateforme en cours de développement facilitant la mise en relation entre clients, restaurateurs et producteurs locaux.`,
      image: "../assets/agriculture-local-territoire-filiere-agricole.webp",
      badges: [
        "Angular",
        "Java",
        "Spring Boot",
        "MySQL",
        "Docker",
        "Google Cloud Platform",
      ],
    },
    {
      title: "Cortex IA",
      description: `Le projet Cortex IA est une application web qui permet de traduire les ondes cérébrales en mouvements prédictifs en quasi-temps réel. Grâce à un casque spécialisé captant les ondes cérébrales, les utilisateurs peuvent interagir avec des jeux directement par la pensée, créant ainsi une expérience utilisateur avant-gardiste.`,
      image: "../assets/UCM3.webp",
      badges: [
        "OpenBCI",
        "Python",
        "Mlops",
        "Java",
        "Spring Boot",
        "Angular",
        "Elastic",
        "MySQL",
        "Docker",
        "Azure DevOps",
        "SCRUM",
      ],
    },
    {
      title: "Lib Éduc",
      description:
        "Plateforme de mise en relation entre Professionnels spécialisés dans les troubles mentaux et les familles en recherche d'accompagnement.",
      image: "../assets/libeduc.webp",
      badges: [
        "Ruby on Rails",
        "Stimulus.js",
        "Stripe",
        "PostgreSQL",
        "Redis",
        "Clever Cloud",
        "SEO",
      ],
    },
    {
      title: "Les Boisiers",
      description:
        "Site vitrine destiné à un restaurateur local. Il présente l'établissement, la carte et les événements à venir. ",
      image: "../assets/Capture_d_écran_2023-01-17_181639_ysttim.webp",
      badges: [
        "Ruby on Rails",
        "Stimulus.js",
        "Bootstrap",
        "PostgreSQL",
        "Heroku",
        "SEO",
      ],
    },
    {
      title: "Feed My plant",
      description:
        "Feed My Plant est une application qui, grâce à l'intégration de capteurs embarqués Arduino, vous permet de surveiller en temps réel le taux d'humidité dans le pot de vos plantes, créant ainsi une harmonie entre matériel et logiciel pour une maintenance optimale de vos plantes. L'objectif principal de cette application est d'empêcher vos plantes de mourir par négligence. Dans l'interface de l'application, vous pouvez trouver des informations détaillées sur l'entretien de vos plantes, ainsi que des conseils pratiques pour en prendre soin. De plus, l'application vous envoie des alertes en temps réel vous aidant ainsi à agir rapidement pour les sauver.",
      image: "../assets/feedmyplant.webp",
      badges: [
        "Arduino",
        "C++",
        "Ruby on Rails",
        "Stimulus.js",
        "PostgreSQL",
        "Heroku",
        "Progressive Web App",
        "SEO",
      ],
    },

    {
      title: "Jardi'Ca",
      description: `Site vitrine pour un paysagiste, présentant l'entreprise et les services proposés.`,
      image: "../assets/Capture_d_écran_2023-09-07_224504_n3bdlg.webp",
      badges: [
        "Ruby on Rails",
        "Stimulus.js",
        "Bootstrap",
        "PostgreSQL",
        "Fly.io",
      ],
    },
  ];

  return (
    <main>
      {activeSection === "WORK" ? (
        articles.map((article, index) => (
          <Article
            key={index}
            index={index}
            status={index === activeIndex ? "active" : "inactive"}
            title={article.title}
            description={article.description}
            badges={article.badges}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
          />
        ))
      ) : activeSection === "CAREERS" ? (
        <Careers />
      ) : activeSection === "ABOUT" ? (
        <About setActiveSection={setActiveSection} />
      ) : (
        <ContactForm />
      )}
    </main>
  );
};

export default Main;
