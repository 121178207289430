import React, { useEffect } from "react";
import "./Article.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const Article = ({
  index,
  status,
  title,
  description,
  image,
  badges = [],
  handleLeftClick,
  handleRightClick,
}) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        handleLeftClick();
      } else if (e.key === "ArrowRight") {
        handleRightClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleLeftClick, handleRightClick]);

  return (
    <article data-index={index} data-status={status}>
      <div className="article-image-section article-section">{image}</div>
      <div className="article-description-section article-section">
        <p>{description}</p>
        <div className="article-badges">
          {badges.map((badge, badgeIndex) => (
            <span key={badgeIndex} className="badge">
              {badge}
            </span>
          ))}
        </div>
      </div>
      <div className="article-title-section article-section">
        <h2>{title}</h2>
        <FontAwesomeIcon icon={faPlus} size="5x" id="faplus-favicon" />
      </div>
      <div className="article-nav-section article-section">
        <button
          className="article-nav-button"
          type="button"
          onClick={handleLeftClick}
          aria-label="Précédent"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="faIcon" />
        </button>
        <button
          className="article-nav-button"
          type="button"
          onClick={handleRightClick}
          aria-label="Suivant"
        >
          <FontAwesomeIcon icon={faArrowRight} className="faIcon" />
        </button>
      </div>
    </article>
  );
};

export default Article;
