import React, { useState } from "react";
import "./Logo.scss";

function Logo() {
  const [primaryColor, setPrimaryColor] = useState("#ffffff");

  return (
    <lord-icon
      className="logo"
      src="https://cdn.lordicon.com/tkgyrmwc.json"
      trigger="loop"
      delay="500"
      colors={`primary:${primaryColor},secondary:#e85d04`}
      style={{ width: "48px", height: "48px" }}
      onMouseEnter={() => setPrimaryColor("#e85d04")}
      onMouseLeave={() => setPrimaryColor("#ffffff")}
    ></lord-icon>
  );
}

export default Logo;
