import React from "react";
import "./Careers.scss";

const Careers = () => {
  return (
    <div id="timeline-content">
      <div className="timeline-heading">
        <h2>Mon parcours</h2>
      </div>
      <ul className="timeline">
        <li className="event" data-date="Mai 2023 - En poste">
          Mai 2023 - En poste
          <h3>Sogeti - Développeur Full Stack Java et Angular</h3>
          <p>
            En tant que développeur Full Stack chez Sogeti, je travaille sur
            divers projets utilisant Java et Angular. Contribuant activement au
            développement et à l'amélioration des applications web, je mets en
            œuvre mes compétences en Java et Angular pour fournir des solutions
            efficaces et innovantes.
          </p>
        </li>
        <li className="event" data-date="Février - Avril 2023">
          Février - Avril 2023
          <h3>Formation chez M2I</h3>
          <p>
            Formation en tant que Développeur Java Full Stack. Étude approfondie
            de divers sujets tels que la maîtrise de Java, le développement de
            Web Services, l'utilisation d'Angular, et la mise en œuvre des
            principes DevOps. Obtention de la certification Scrum.
          </p>
        </li>

        <li className="event" data-date="Octobre - Novembre 2022">
          Octobre - Novembre 2022
          <h3>Free-lance : Les Boisiers</h3>
          <p>
            Mission de conception et développement d'un site web pour un
            restaurant. Responsabilités principales : développement d'une
            interface responsive, mise en place d'un formulaire de contact et
            optimisation du référencement naturel (SEO).
          </p>
        </li>

        <li className="event" data-date="Août - Novembre 2022">
          Août - Novembre 2022
          <h3>Free-lance : Lib Éduc</h3>
          <p>
            Développement d'une plateforme e-commerce pour les professionnels de
            santé et les particuliers atteints de troubles neurologiques,
            intégrant des solutions de paiement sécurisées et des
            fonctionnalités dédiées pour chaque type d'utilisateur.
          </p>
        </li>

        <li className="event" data-date="Avril - Juin 2022">
          Avril - Juin 2022
          <h3>Formation au Wagon, Nantes</h3>
          <p>
            Reconversion professionnelle en tant que développeur Ruby Full
            Stack. Participation au projet final de développement d'une
            application combinant logiciel et matériel pour l'entretien des
            plantes, intégrant un capteur embarqué Arduino.
          </p>
        </li>

        <li className="event" data-date="Février 2018 - Février 2022">
          Février 2018 - Février 2022
          <h3>Agent de transit Maritime international</h3>
          <p>
            Responsable de la gestion de l'information liée au transport et à la
            logistique, coordination avec les partenaires et prestataires de
            services, et gestion des relations clients.
          </p>
        </li>

        <li className="event" data-date="Septembre 2017 - Février 2018">
          Septembre 2017 - Février 2018
          <h3>Agent de Handling import et export</h3>
          <p>
            Gestion des opérations de transport et de logistique, avec un focus
            sur l'importation et l'exportation de biens.
          </p>
        </li>

        <li className="event" data-date="Avril - Juillet 2016">
          Avril - Juillet 2016
          <h3>Stagiaire assistant commercial export</h3>
          <p>
            Stage en tant qu'assistant commercial export, concentré sur l'écoute
            et la compréhension des besoins des clients.
          </p>
        </li>

        <li className="event" data-date="Octobre - Décembre 2014">
          Octobre - Décembre 2014
          <h3>Stagiaire Agent de transit maritime import</h3>
          <p>
            Stage en tant qu'agent de transit maritime, spécialisé dans les
            opérations d'importation.
          </p>
        </li>

        <li className="event" data-date="Février - Avril 2014">
          Février - Avril 2014
          <h3>Stagiaire Agent de transit maritime export</h3>
          <p>
            Stage en tant qu'agent de transit maritime, spécialisé dans les
            opérations d'exportation.
          </p>
        </li>

        <li className="event" data-date="2013 - 2016">
          2013 - 2016
          <h3>Jobs étudiant</h3>
          <p>
            Emplois variés incluant des rôles de livreur, travailleur dans le
            BTP, et préparateur de commande.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default Careers;
