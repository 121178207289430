import React, { useState } from "react";
import "./ContactForm.scss";
import { toast } from "react-toastify";
import ConfettiComponent from "../Confetti/Confetti";
import SubmitButton from "../SubmitButton/SubmitButton";

const ContactForm = () => {
  const formRef = React.useRef();
  const nameRef = React.useRef();
  const emailRef = React.useRef();
  const messageRef = React.useRef();

  const [errors, setErrors] = useState({});

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fieldValidity, setFieldValidity] = useState({
    name: null,
    email: null,
    message: null,
  });

  const validate = (name, email, message) => {
    const newErrors = {};
    if (!validateName(name)) newErrors.name = "Le nom est requis ❌";
    if (!validateEmail(email)) newErrors.email = "L'email est invalide ❌";
    if (!validateMessage(message))
      newErrors.message = "Le message est requis ❌";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const validateName = (name) => {
    if (!name) {
      setFieldValidity((prevState) => ({ ...prevState, name: false }));
      return false;
    }
    setFieldValidity((prevState) => ({ ...prevState, name: true }));
    return true;
  };

  const validateEmail = (email) => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setFieldValidity((prevState) => ({ ...prevState, email: false }));
      return false;
    }
    setFieldValidity((prevState) => ({ ...prevState, email: true }));
    return true;
  };

  const validateMessage = (message) => {
    if (!message) {
      setFieldValidity((prevState) => ({ ...prevState, message: false }));
      return false;
    }
    setFieldValidity((prevState) => ({ ...prevState, message: true }));
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const message = messageRef.current.value;

    if (validate(name, email, message)) {
      try {
        setLoading(true);
        toast("Envoi en cours...🌐", { type: "info", ariaLive: "polite" });
        const response = await fetch(
          "https://europe-west1-portfolio2023-92a4c.cloudfunctions.net/sendEmail",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name,
              email,
              message,
            }),
          }
        );

        if (!response.ok) {
          toast("Erreur lors de l'envoi du message 😶", {
            type: "error",
            ariaLive: "assertive",
          });
          throw new Error("Network response was not ok " + response.statusText);
        }

        toast("Message envoyé ! 👌", { type: "success", ariaLive: "polite" });
        window.dispatchEvent(new Event("confetti"));
        formRef.current.reset();

        setErrors({});
        setFieldValidity({
          name: null,
          email: null,
          message: null,
        });
        setLoading(false);
        setSuccess(true);
      } catch (error) {}
    } else {
      toast("Veuillez remplir les champs correctement 😬", { type: "error" });
      setLoading(false);
    }
  };

  return (
    <div className="contact-form" aria-labelledby="Formulaire de contact">
      <ConfettiComponent />
      <form onSubmit={handleSubmit} ref={formRef}>
        <h2>Contactez-moi</h2>
        <label>
          Nom 
          <input
            type="text"
            name="name"
            placeholder="Entrez votre nom"
            ref={nameRef}
            className={
              fieldValidity.name === null
                ? ""
                : fieldValidity.name
                ? "valid"
                : "invalid"
            }
            onChange={(e) => validateName(e.target.value)}
            aria-label="Nom"
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </label>
        <label>
          Email
          <input
            type="email"
            name="email"
            placeholder="Entrez votre email"
            ref={emailRef}
            className={
              fieldValidity.email === null
                ? ""
                : fieldValidity.email
                ? "valid"
                : "invalid"
            }
            onChange={(e) => validateEmail(e.target.value)}
            aria-label="Email"
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </label>
        <label>
          Message
          <textarea
            name="message"
            placeholder="Entrez votre message"
            ref={messageRef}
            className={
              fieldValidity.message === null
                ? ""
                : fieldValidity.message
                ? "valid"
                : "invalid"
            }
            onChange={(e) => validateMessage(e.target.value)}
            aria-label="Message"
          />
          {errors.message && <span className="error">{errors.message}</span>}
        </label>
        <div className="submit-container">
          <SubmitButton
            type="submit"
            loading={loading}
            success={success}
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
