/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./Navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-toastify";
import Logo from "../Logo/Logo";
import "react-toastify/dist/ReactToastify.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Navbar = ({ setActiveSection, activeSection }) => {
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    const toastId = "welcome-toast";
    if (!toast.isActive(toastId)) {
      toast("👋 Bienvenue !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId,
        type: "info",
        icon: false,
      });
    }
  }, []);

  // const handleNavToggle = () => {
  //   setToggled(!toggled);
  // };

  const handleNavToggle = (event) => {
    event.preventDefault();
    setToggled(!toggled);
  };

  return (
    <nav data-toggled={toggled} data-transitionable="false">
      <div id="nav-logo-section" className="nav-section">
        <a
          href="#"
          aria-label="Accueil"
          onClick={() => setActiveSection("WORK")}
        >
          <span>LUCAS VITTAZ</span>
          <Logo />
        </a>
      </div>
      <div id="nav-mobile-section">
        <div id="nav-link-section" className="nav-section">
          <a
            href="#"
            aria-label="A propos"
            onClick={(event) => {
              setActiveSection("ABOUT");
              handleNavToggle(event);
            }}
            className={activeSection === "ABOUT" ? "active-link" : ""}
          >
            A PROPOS
          </a>
          <a
            href="#"
            aria-label="Mes projets"
            onClick={(event) => {
              setActiveSection("WORK");
              handleNavToggle(event);
            }}
            className={activeSection === "WORK" ? "active-link" : ""}
          >
            MES PROJETS
          </a>
          <a
            href="#"
            aria-label="Mon parcours"
            onClick={(event) => {
              setActiveSection("CAREERS");
              handleNavToggle(event);
            }}
            className={activeSection === "CAREERS" ? "active-link" : ""}
          >
            CV
          </a>
        </div>
        <div id="nav-social-section" className="nav-section">
          <a
            href="https://github.com/lucas-vittaz"
            target="_blank"
            rel="noreferrer"
            aria-label="Github"
          >
            <FontAwesomeIcon icon={faGithub} size="2x" />
          </a>
          <a
            href="https://fr.linkedin.com/in/lucas-vittaz"
            target="_blank"
            rel="noreferrer"
            aria-label="Linkedin"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </div>
        <div id="nav-contact-section" className="nav-section">
          <a
            href="#"
            aria-label="Me contacter"
            onClick={(event) => {setActiveSection("CONTACT"); handleNavToggle(event);}}
            className={activeSection === "CONTACT" ? "active-link" : ""}
          >
            ME CONTACTER
          </a>
        </div>
      </div>
      <button id="nav-toggle-button" type="button" onClick={handleNavToggle}>
        <span>Menu</span>
        <FontAwesomeIcon icon={faBars} size="2x" />
      </button>
    </nav>
  );
};

export default Navbar;
