/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import "./About.scss";
import profilePic from "../../assets/lucas-vittaz_mugx1f.webp";

const About = ({ setActiveSection }) => {
  return (
    <div className={`about-container`}>
      <div className="about-content">
        <h2>A propos</h2>
        <p>
          En tant que développeur web passionné par les nouvelles technologies,
          je suis enthousiaste à l'idée de mettre mes compétences et mon
          dynamisme au service de votre entreprise pour développer des solutions
          web sur mesure. Mon impatience de découvrir de nouvelles technologies
          et mon goût pour les projets stimulants me permettent de m'adapter
          rapidement à votre entreprise et de contribuer à son développement.
        </p>

        <div className="navigation-buttons">
          <div className="button" onClick={() => setActiveSection("WORK")}>
            Voir mes projets
          </div>
          <div className="button" onClick={() => setActiveSection("CAREERS")}>
            Mon parcours
          </div>
          <div className="button" onClick={() => setActiveSection("CONTACT")}>
            Me contacter
          </div>
        </div>
      </div>
      <img
        src={profilePic}
        alt="about me"
      />
    </div>
  );
};

export default About;
